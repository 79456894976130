@import "fonts";
@import "loader";
@import "vars";
@import "normalize";
@import "mixins";

* {
  transition: $transition-image;
  box-sizing: border-box; }

::-webkit-scrollbar {
  width: 0px; }

body {
  position: relative;
  z-index: 0;
  font-family: 'sf-semibold';
  font-size: 1rem;
  background: #F8F6F2;
  color: $black;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  text-rendering: optimizelegibility; }

p {
  margin: 0;
  margin-bottom: 1rem; }

a {
  color: inherit;
  transition: none;
  &:hover {
    color: black; } }

.ru, .en {
  opacity: 0; }

.container {
  padding: 15px;
  font-size: calc(0.325rem + 1vw);
  line-height: 1.25;
  letter-spacing: -0.025rem;
  @media screen and ( $phone ) {
    font-size: calc(0.625rem + 1vw); } }

.activities {
  transition: none;
  p {
    transition: none;
    font-size: calc(0.325rem + 1vw);
    line-height: 1.25;
    letter-spacing: -0.025rem;
    @media screen and ( $phone ) {
      font-size: calc(0.625rem + 1vw); } } }

.text {
  margin-top: 50vh;
  width: 50%;
  @media screen and ( $phone ) {
    width: 100%; } }

.projects, .activities {
  margin-top: 1rem; }

.activities {
  font-size: calc(0.125rem + 1vw);
  @media screen and ( $phone ) {
    font-size: calc(0.625rem + 1vw); } }

.activity {
  transition: none;
  display: flex;
  padding: 5px 0;
  border-top: 2px solid $black;
  &:last-child {
    border-bottom: 2px solid $black; }
  a {
    text-decoration: none; }
  span {
    transition: none; } }

.images {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  div {
    width: inherit;
    height: inherit;
    position: fixed; }
  .image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    opacity: 0;
    background-color: black; }
  .images__derive, .images__high, .images__transport {
    height: 100%; } }

.sound-popup {
  position: absolute;
  display: none;
  opacity: 0;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  z-index: 10;
  div {
    margin: 10px 0; }
  &__wrapper {
    text-align: center; }
  &__on, &__off {
    border: 1px solid $black;
    border-radius: 20px;
    padding: 5px;
    cursor: pointer;
    transition: none;
    &:hover {
      background-color: #dbdbdb; } } }

.lang-popup {
  position: fixed;
  width: 100%;
  display: flex;
  top: -100px;
  justify-content: center;
  align-items: center;
  z-index: 10;
  transform: translateY(-115px);
  @media screen and ( $phone ) {
    font-size: calc(0.625rem + 1vw); }

  div {
    padding: 25px;
    @media screen and ( $phone ) {
      padding: 10px; } }
  &__wrapper {
    text-align: center;
    background-color: $white;
    border-radius: 15px;
    box-shadow: 2px 2px 100px 3px #223c5036; }
  &__switch {
    cursor: pointer; } }
