
// Size
$desktop-width            : 1235px;
$sm-desktop-width         : 1234px;
$mobile-width             : 1000px;
$default-width            : 1000px;

$phone                    : 'max-width: 567px';
$tablet                   : 'min-width: 568px';
$mobile                   : 'max-width: 1024px';
$laptop                   : 'min-width: 1025px';
$desktop                  : 'min-width: 1235px';

// Padding
$default-padding          : 40px;

// Color
$red                      : #F10E0E;
$green                    : #36d213;
$white                    : #EAEAEA;
$black                    : rgba(0, 0, 0, 0.836);

// Transitions
$transition-image: all 500ms cubic-bezier(0.83, 0, 0.17, 1);
$transition: all 2000ms ease;

